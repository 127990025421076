export default function Kimono(props: { size?: number }) {
  return (
    <svg
      width={props.size ?? "28"}
      height={props.size ?? "28"}
      viewBox="0 0 91 91"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1524_239)">
        <path d="M51.504 57.707L54.0141 61.4382C55.0002 62.9038 54.6113 64.8911 53.1459 65.877C51.6796 66.863 49.6927 66.4738 48.7071 65.0086L45.4978 60.2385L42.2886 65.0086C41.3027 66.4742 39.3151 66.863 37.8499 65.877C36.3843 64.8911 35.9955 62.9036 36.9814 61.4382L39.4916 57.707H22.7031V72.6867C22.7031 74.453 24.1351 75.8848 25.9013 75.8848H65.0953C66.8617 75.8848 68.2935 74.4528 68.2935 72.6867V57.707H51.504V57.707Z" />
        <path d="M28.3545 13H25.9943C24.1766 13 22.7031 14.4361 22.7031 16.2073V53.3087H68L28.3545 13Z" />
        <path d="M37.0547 13.1152L45.4978 21.9239L53.9409 13.1152H37.0547Z" />
        <path d="M65.0969 13.1152H62.803L49.9297 26.5459L68.295 45.7066V16.3134C68.2952 14.5469 66.8634 13.1152 65.0969 13.1152Z" />
        <path d="M90.2162 40.3812L71.4922 18.8047V53.9453L74.2522 56.8079C75.5268 58.1299 77.6496 58.1086 78.8982 56.7642L90.144 44.6535C91.2556 43.4567 91.2865 41.6148 90.2162 40.3812Z" />
        <path d="M0.78262 40.3811C-0.287699 41.6148 -0.256773 43.4565 0.854424 44.6535L12.1002 56.7642C13.3499 58.11 15.4729 58.1283 16.7462 56.8079L19.5064 53.9451V18.8047L0.78262 40.3811Z" />
      </g>
      <defs>
        <clipPath id="clip0_1524_239">
          <rect
            width="91"
            height="91"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
