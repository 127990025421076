import clsx from "clsx"
import type { FC } from "react"

type ToggleButtonProps = {
  leftTitle: string
  leftAction: () => void
  rightTitle: string
  rightAction: () => void
  activeSide: "left" | "right"
}

export const ToggleButton: FC<ToggleButtonProps> = ({ leftTitle, leftAction, rightTitle, rightAction, activeSide }) => {
  return (
    <div className={"flex"}>
      <div
        className={clsx(
          "flex items-center justify-center rounded-tl-lg rounded-bl-lg px-2 py-1",
          activeSide === "left"
            ? "cursor-default bg-emerald-500 text-emerald-900"
            : "shadow-inner-lg cursor-pointer bg-gray-200 text-gray-400"
        )}
        onClick={leftAction}
      >
        <span className={"font-gilroy-medium"}>{leftTitle}</span>
      </div>
      <div
        className={clsx(
          "flex items-center justify-center rounded-tr-lg rounded-br-lg px-2 py-1",
          activeSide === "right"
            ? "cursor-default bg-rose-500 text-rose-900"
            : "shadow-inner-lg cursor-pointer bg-gray-200 text-gray-400"
        )}
        onClick={rightAction}
      >
        <span className={"font-gilroy-medium"}>{rightTitle}</span>
      </div>
    </div>
  )
}
