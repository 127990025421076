import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"
import LazyImportPlugin from "./lazyImport"

type Namespaces = [
  "app",
  "login",
  "reflexe",
  "phishing",
  "profile",
  "elearning",
  "supervision",
  "home",
  "welcome",
  "organization",
  "training",
  "error401",
  "maintenance",
  "challenge",
  "learningPage",
  "tools",
  "licenceExpired",
  "discoveryLicence",
  "userScore",
  "newAdminForm",
  "avatar",
]

const langs = ["en", "fr", "es", "de"]
const namespaces: Namespaces = [
  "app",
  "login",
  "reflexe",
  "phishing",
  "profile",
  "elearning",
  "supervision",
  "home",
  "welcome",
  "organization",
  "training",
  "error401",
  "maintenance",
  "challenge",
  "learningPage",
  "tools",
  "licenceExpired",
  "discoveryLicence",
  "userScore",
  "newAdminForm",
  "avatar",
]

i18n
  .use(LanguageDetector)
  .use(LazyImportPlugin)
  .use(initReactI18next)
  .init({
    fallbackLng: "fr",
    detection: {
      order: ["localStorage", "navigator"],
      caches: ["localStorage"],
      lookupLocalStorage: "lang",
      convertDetectedLanguage: lng => lng.split("-")[0],
    },
    supportedLngs: langs,
    ns: namespaces,
    defaultNS: "app",
    load: "currentOnly",
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    react: { useSuspense: true },
    missingKeyHandler: (lng, ns, key) => {
      console.error(`${ns} ${key} ${lng}`)
    },
  })

export default i18n
