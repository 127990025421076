import ScrollText from "@kamae-apps/shared/old/Component/ScrollText/ScrollText"
import type { TFunction } from "i18next"
import type { FC } from "react"
import { useLocation } from "react-router-dom"
import { getTitle } from "../../../utils"
import { User } from "../User/User"

interface HeaderProps {
  t: TFunction
  loaded: boolean
}

export const Header: FC<HeaderProps> = ({ loaded, t }) => {
  const uri = useLocation()

  return (
    <header className={"flex h-20 items-center justify-center"}>
      <ScrollText className={"font-gilroy-bold my-4 w-full text-center text-4xl font-bold md:w-auto md:text-left"}>
        {getTitle(uri.pathname, t)}
      </ScrollText>
      <div className={"hidden grow md:block"}>
        <User
          t={t}
          loaded={loaded}
        />
      </div>
    </header>
  )
}
