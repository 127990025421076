import PrimaryButton from "@kamae-apps/shared/old/Component/Button/PrimaryButton"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

export default function Error404() {
  const { t } = useTranslation("app")

  const navigate = useNavigate()
  return (
    <div className={"flex w-full grow flex-col items-center justify-center"}>
      {t("app:notFound.text")}
      <PrimaryButton onClick={() => navigate("/home")}>{t("app:notFound.button")}</PrimaryButton>
    </div>
  )
}
