import type TUser from "@kamae-apps/shared/Types/TUser"
import { apiRequest } from "@kamae-apps/shared/utils"
import { useCallback } from "react"
import { useUserSharedState } from "./Context"

export default function useUser() {
  const [user, setUser] = useUserSharedState()
  return {
    user: user,
    setUser: useCallback(
      (user: ((prev: TUser) => TUser) | TUser) => {
        if (typeof user === "function") {
          setUser(prev => {
            let data = user(prev)
            apiRequest("/user", {
              method: "PUT",
              body: {
                name: data.last_name,
                first_name: data.first_name,
                avatar: data.avatar,
              },
            })
            return data
          })
        } else if (typeof user === "object") {
          setUser(user)
          apiRequest("/user", {
            method: "PUT",
            body: {
              name: user.last_name,
              first_name: user.first_name,
              avatar: user.avatar,
            },
          })
        }
      },
      [setUser]
    ),
    setUserState: setUser,
  }
}
