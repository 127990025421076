import { Scope, scopeHas } from "@kamae-apps/shared/Types/Scope"
import clsx from "clsx"
import type { TFunction } from "i18next"
import type { FC } from "react"
import { Users } from "react-feather"
import { useLocation } from "react-router-dom"
import useUser from "../../hooks/useUser"
import DashBoard from "../Icons/DashBoard"
import Document from "../Icons/Document"
import Kimono from "../Icons/Kimono"
import Organization from "../Icons/Organization"
import Phishing from "../Icons/Phishing"
import Supervision from "../Icons/Supervision"
import Link from "../Link/Link"

interface LinkMenuProps {
  supervision: boolean
  isTeamLeader: boolean
  t: TFunction
  active: boolean
}

export const LinkMenu: FC<LinkMenuProps> = ({ supervision, isTeamLeader, t, active }) => {
  const uri = useLocation()
  const { user } = useUser()

  const phishingIsAvailable = active && user.company.phishing_enabled

  const css = "flex flex-col md:flex-row m-1 md:p-4 md:mx-4 rounded items-center"

  const defaultColor = clsx(active ? "text-gray-500" : "text-gray-200")
  const phishingDefaultColor = clsx(phishingIsAvailable ? "text-gray-500" : "text-gray-200 hover:no-underline")

  const current = "text-primary md:bg-primary-600 md:text-white"

  const cssOrganization = clsx(css, (uri.pathname.startsWith("/organization") && current) || defaultColor)
  const cssSupervision = clsx(css, (uri.pathname.startsWith("/supervision") && current) || defaultColor)
  const cssHome = clsx(css, (uri.pathname.startsWith("/home") && current) || defaultColor)
  const cssTeam = clsx(css, (uri.pathname.startsWith("/team") && current) || defaultColor)
  const cssCours = clsx(
    css,
    ((uri.pathname.startsWith("/elearning") || uri.pathname.startsWith("/challenge")) && current) || defaultColor
  )
  const cssPhishing = clsx(css, (uri.pathname.startsWith("/phishing") && current) || phishingDefaultColor)
  const cssReflexe = clsx(css, (uri.pathname.startsWith("/reflexe") && current) || defaultColor)

  const pCss = "ml-4 font-bold inline hidden md:block"

  return (
    <div
      className={clsx(
        "custom-scrollbar drop-shadow-menu fixed bottom-0 left-0 z-20 flex h-16 w-full",
        "items-center justify-around overflow-auto bg-white",
        "md:static md:block md:h-auto md:flex-col md:items-stretch md:drop-shadow-none"
      )}
    >
      {supervision && (
        <>
          <Link
            active={active}
            className={cssOrganization}
            link={"/organization"}
          >
            <Organization />
            <p className={pCss}>{t("app:sidebar.organization.label")}</p>
          </Link>
          <Link
            active={active}
            className={cssSupervision}
            link={"/supervision"}
          >
            <Supervision />
            <p className={pCss}>{t("app:sidebar.supervision.label")}</p>
          </Link>
        </>
      )}
      {isTeamLeader && (
        <Link
          active={active}
          className={cssTeam}
          link={"/team"}
        >
          <Users className="no-fill" />
          <p className={pCss}>{t("app:sidebar.team.label")}</p>
        </Link>
      )}
      <Link
        active={active}
        className={cssHome}
        link={"/home"}
      >
        <DashBoard />
        <p className={pCss}>{t("app:sidebar.home.label")}</p>
      </Link>
      <Link
        active={active}
        className={cssCours}
        link={"/elearning"}
      >
        <Kimono />
        <p className={pCss}>{t("app:sidebar.elearning.label")}</p>
      </Link>
      {(user.company.phishing_enabled || scopeHas(user?.scope, Scope.COMPANY_ADMIN)) && (
        <Link
          active={phishingIsAvailable}
          title={user.company.phishing_enabled ? "" : t("app:sidebar.phishing.disabled")}
          className={cssPhishing}
          link={"/phishing"}
        >
          <Phishing />
          <p className={pCss}>{t("app:sidebar.phishing.label")}</p>
        </Link>
      )}
      <Link
        active={active}
        className={cssReflexe}
        link={"/reflexes"}
      >
        <Document />
        <p className={pCss}>{t("app:sidebar.reflexe.label")}</p>
      </Link>
    </div>
  )
}
