import useApiStatus from "@kamae-apps/shared/Hooks/useApiStatus/useApiStatus"
import Loading from "@kamae-apps/shared/old/Component/Loading/Loading"
import * as React from "react"
import { Suspense } from "react"
import { Route, Routes } from "react-router-dom"
import MaintenancePage from "./old/Error/MaintenancePage/MaintenancePage"

const NewLogin = React.lazy(() => import("./Routes/Login/LoginPage"))

export default function App() {
  const apiStatus = useApiStatus()

  return (
    <div data-theme={"rebranding"}>
      {((apiStatus.code === 503 || apiStatus.code === -1) && <MaintenancePage />) || (
        <Routes>
          <Route
            path={"/login"}
            element={
              <Suspense fallback={<Loading />}>
                <NewLogin />
              </Suspense>
            }
          />
        </Routes>
      )}
    </div>
  )
}
