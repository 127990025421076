import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import useIntercom from "../../hooks/useIntercom"

const useMaintenancePage = () => {
  const { t } = useTranslation("maintenance")
  const { instantiateIntercom } = useIntercom()

  useEffect(() => {
    instantiateIntercom()
  }, [instantiateIntercom])

  return { t }
}

export default useMaintenancePage
