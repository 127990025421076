import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import config from "../variable"

const DOC_ADMIN_PREFIX = "/doc-admin"
const DEFAULT_DOC_ADMIN_LANGUAGE = "fr"

export enum DocumentationLinksPathsEnum {
  Home = "",
  StarterDeployment = "configuration/starter-deployment",
  PhishingConfiguration = "configuration/phishing",
  ScimConfiguration = "configuration/scim",
  SsoConfiguration = "configuration/sso",
  PreboardingEmails = "configuration/preboarding-emails",
  EmailWhitelisting = "configuration/email-whitelisting",
  PhishingAlertButton = "usage/phishing/alert-button/",
  MailTrapEditor = "usage/trap-editor",
  LandingPageTrapEditor = "usage/trap-editor#éditer-le-contenu-html-de-la-landing-page",
  UserRights = "usage/user-rights",
}

export const useDocAdmin = (path: DocumentationLinksPathsEnum): string => {
  const { i18n } = useTranslation()

  return useMemo(() => {
    if (i18n.language === DEFAULT_DOC_ADMIN_LANGUAGE.toString()) {
      return `${config.api}${DOC_ADMIN_PREFIX}/${path}`
    }

    return `${config.api}${DOC_ADMIN_PREFIX}/${i18n.language}/${path}`
  }, [i18n.language, path])
}
