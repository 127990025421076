import Loadable from "@kamae-apps/shared/old/Component/Loadable/Loadable"
import Link from "../../Link/Link"
import "@kamae-apps/shared/Style/Loadable.css"
import { FC, useState } from "react"

interface UserprofileProps {
  img: string
  name: string
  team: string
  first_name: string
  loaded: boolean
  isProfileLinkActive: boolean
}

const UserProfile: FC<UserprofileProps> = ({ img, name, team, first_name, loaded, isProfileLinkActive }) => {
  const [imageLoaded, setImageLoaded] = useState(false)

  return (
    <div className={"flex w-full flex-col items-center p-4"}>
      <Link
        active={isProfileLinkActive}
        link={"/profile"}
        className={"absolute -top-1/3 flex h-full w-full justify-center md:static"}
      >
        <div className={"flex h-40 w-40 items-center justify-center rounded-full border-4 border-white bg-gray-100"}>
          <img
            src={img}
            className={`mt-4 h-40 ${imageLoaded ? "" : "hidden"}`}
            alt={"Avatar"}
            onLoad={() => setImageLoaded(true)}
          />
          <img
            src={"avatarLoading.png"}
            className={`mt-4 h-40 ${imageLoaded ? "hidden" : "loadable"}`}
            alt={"Avatar"}
          />
        </div>
      </Link>
      <Loadable
        loaded={loaded && (name !== "" || first_name !== "")}
        minWidth={"min-w-32"}
        className={"mt-4"}
        minHeight={"min-h-6"}
      >
        <p className={"text-center font-bold text-white"}>
          {first_name} {name}
        </p>
      </Loadable>
      <Loadable
        loaded={loaded && team !== ""}
        minHeight={"min-h-5"}
        minWidth={"min-w-16"}
        className={"mt-1"}
      >
        <p className={"font-gilroy text-center text-sm text-white"}>{team}</p>
      </Loadable>
    </div>
  )
}

export default UserProfile
