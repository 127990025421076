import { ConditionalRender } from "@kamae-apps/shared/old/Component/ConditionalRender/ConditionalRender"
import { DropDown } from "@kamae-apps/shared/old/Component/DropDown/DropDown"
import { useTranslation } from "react-i18next"
import { getFlag } from "../../../utils"
import { useFeatureFlags } from "../../hooks/posthog/useFeatureFlags"

interface Langue {
  flag: string
  code: string
  name: string
}

interface FlagItemProps extends Langue {
  setLang?: (v: string) => void
}

function FlagItem(props: FlagItemProps) {
  return (
    <div
      role="button"
      onClick={() => {
        props.setLang?.(props.code)
      }}
      title={props.name}
      className={"mx-1"}
    >
      {getFlag(props.code)}
    </div>
  )
}
interface FlagProps {
  allLangs?: boolean
}
export default function Flag(props: FlagProps) {
  const { i18n } = useTranslation()
  const featureFlags = useFeatureFlags()

  const languages = [
    { flag: "🇫🇷", code: "fr", name: "Français" },
    { flag: "🇬🇧", code: "en", name: "English" },
  ]

  if (featureFlags.includes("spanish") || props.allLangs) {
    languages.push({ flag: "🇪🇸", code: "es", name: "Spanish" })
  }

  if (featureFlags.includes("german") || props.allLangs) {
    languages.push({ flag: "🇩🇪", code: "de", name: "German" })
  }

  const selectedFlag = languages.find(lang => lang.code === i18n.language) ?? languages[0]

  return (
    <div className={"flex items-center"}>
      <DropDown title={<FlagItem {...selectedFlag} />}>
        <div className="flex flex-col">
          {languages.map((v, i) => (
            <ConditionalRender
              key={i}
              condition={v.code !== selectedFlag.code}
            >
              <FlagItem
                setLang={i18n.changeLanguage}
                {...v}
              />
            </ConditionalRender>
          ))}
        </div>
      </DropDown>
    </div>
  )
}
