import { DocumentationLinksPathsEnum, useDocAdmin } from "@kamae-apps/shared/Hooks/useDocAdmin"
import { Modal } from "@kamae-apps/shared/old/Component/NewModal/Modal"
import { FC, useState } from "react"
import { useTranslation } from "react-i18next"
import Link from "../Link/Link"
import { DiscoveryButton } from "./DiscoveryButton"

export const DiscoveryPill: FC = () => {
  const { t } = useTranslation("discoveryLicence")
  const [discoveryModalDisplay, setDiscoveryModalDisplay] = useState(false)

  return (
    <>
      <DiscoveryModalPill
        discoveryModalDisplay={discoveryModalDisplay}
        setDiscoveryModalDisplay={setDiscoveryModalDisplay}
      />
      <div
        onClick={() => {
          setDiscoveryModalDisplay(true)
        }}
        className="cursor-pointer rounded-full bg-purple-100 px-1 py-2 md:px-5"
      >
        <span className="whitespace-nowrap text-center text-purple-600">{t("discoveryLicence:licenceName")}</span>
      </div>
    </>
  )
}

type DiscoveryModalPillProps = {
  discoveryModalDisplay: boolean
  setDiscoveryModalDisplay: (v: boolean) => void
}

const DiscoveryModalPill: FC<DiscoveryModalPillProps> = ({ discoveryModalDisplay, setDiscoveryModalDisplay }) => {
  const { t } = useTranslation("discoveryLicence")
  const starterDeploymentDocUrl = useDocAdmin(DocumentationLinksPathsEnum.StarterDeployment)

  return (
    <Modal
      title={t("discoveryLicence:completeLicenceName")}
      display={discoveryModalDisplay}
      setDisplay={setDiscoveryModalDisplay}
      width={"md:w-3/5"}
    >
      <div className="[&>p]:font-gilroy-medium p-8 text-black/60">
        <p>{t("discoveryLicence:yourCompanyEnjoysDiscover")}</p>
        <p>{t("discoveryLicence:withFullVersionYouCanAccess")}</p>
        <ul className="[&>li]:font-gilroy-medium mt-5 pl-5">
          <li>• {t("discoveryLicence:fullTrainings")}</li>
          <li>• {t("discoveryLicence:monthlyChallenges")}</li>
          <li>• {t("discoveryLicence:unlimitedPhishingAttacks")}</li>
          <li>• {t("discoveryLicence:dedicatedSupport")}</li>
          <li>• {t("discoveryLicence:andMore")}</li>
        </ul>
      </div>
      <div className="flex flex-col lg:flex-row">
        <Link
          className="m-5 grow whitespace-nowrap rounded border-2 border-purple-600 px-5 py-2 text-center text-purple-600 !no-underline duration-500 hover:bg-purple-600 hover:text-white"
          link={starterDeploymentDocUrl}
          crossSite
        >
          {t("discoveryLicence:understandStarterOffer")}
        </Link>
        <DiscoveryButton className="m-5 grow text-center" />
      </div>
    </Modal>
  )
}
