import useUserConfig from "@kamae-apps/shared/Hooks/userConfig/useUserConfig"
import { CompanyStatus } from "@kamae-apps/shared/Types/Company/CompanyStatus"
import { Scope, scopeHas } from "@kamae-apps/shared/Types/Scope"
import Modal from "@kamae-apps/shared/old/Component/Modal/Modal"
import React, { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import useUser from "../../hooks/useUser"

interface TutorialModalProps {
  display: boolean
  setDisplay: React.Dispatch<React.SetStateAction<boolean>>
}

export const TutorialModal: FC<TutorialModalProps> = ({ display, setDisplay }) => {
  const { configs, setConfig } = useUserConfig()
  const { t, i18n } = useTranslation()
  const { user } = useUser()
  const [opened, setOpened] = useState(false)
  const [link, setLink] = useState("")

  useEffect(() => {
    if (typeof user.scope !== "undefined") {
      if (scopeHas(user.scope, Scope.COMPANY_ADMIN)) {
        //ADMIN
        setLink("https://www.loom.com/embed/adf6bf5840024e65ba3fbb1baa34a6f5?hideEmbedTopBar=false")
      } else {
        if (i18n.language === "fr") {
          setLink("https://www.loom.com/embed/3dc41ef85f5743b786215dc34e2f23bf?hideEmbedTopBar=false")
        } else {
          setLink("https://www.loom.com/embed/e128aa07fd3c466fa490b8a73ca320eb?hideEmbedTopBar=false")
        }
      }
    }
  }, [user.scope, i18n.language])
  useEffect(() => {
    if (configs && configs.get("first_connection") !== "true" && user.company.status !== CompanyStatus.Discovery) {
      setDisplay(true)
      setOpened(true)
    }
  }, [configs, setConfig, setDisplay, user])

  useEffect(() => {
    if (opened && !display) {
      setConfig("first_connection", "true")
      setOpened(false)
    }
  }, [display, setConfig, opened])

  return (
    <Modal
      title={t("app:header.menu.tutorial.title")}
      display={display}
      setDisplay={setDisplay}
      height={"h-[75vh]"}
    >
      <iframe
        title={t("app:header.menu.tutorial.title")}
        src={link}
        allowFullScreen
        className={"h-full w-full"}
      />
    </Modal>
  )
}
