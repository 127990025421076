import type { FC, ReactNode } from "react"

type IntercomLauncherProps = {
  children: ReactNode
  className?: string
}

export const IntercomLauncher: FC<IntercomLauncherProps> = ({ children, className }) => {
  return (
    <div
      id={"intercom-launcher"}
      className={className}
    >
      {children}
    </div>
  )
}
