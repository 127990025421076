import { useEffect, useState } from "react"

type Landbot = {
  onLoad: (callback: () => void) => void
  destroy: () => void
  config: {
    hidden_fields: Record<string, string>
  }
}

export const useLandbot = () => {
  const [landBot, setLandBot] = useState(null)
  const [loadingError, setLoadingError] = useState(false)

  // overwrite close function if we are in the LMS context
  if ((window.parent.window.parent as any).RXD) {
    window.close = function () {
      window.parent.window.parent.postMessage("landBotCloseWindow")
    }
  }
  // disable Intercom
  ;(window as any).Intercom("shutdown")

  // Dynamically load Landbot script
  useEffect(() => {
    const script = document.createElement("script")
    script.src = "https://d2b6e0kv6wqwt0.cloudfront.net/landbot-3/landbot-3.0.0.js"
    script.async = true

    script.onload = () => {
      // @ts-ignore
      setLandBot(Landbot)
    }

    script.onerror = () => {
      setLoadingError(true)
    }

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  useEffect(() => {
    if (!landBot) {
      return
    }

    try {
      const exception = ["H-1098200-61O8JQS8GUSF2MYO"]
      let url = "https://chats.landbot.io/v3/{{LANDBOTID}}/index.json"
      let newUrl = "https://storage.googleapis.com/landbot.pro/v3/{{LANDBOTID}}/index.json"

      const urlParams = new URLSearchParams(window.location.search)

      const title = urlParams.get("title") ?? ""

      const landbotID = urlParams.get("landbotid") ?? ""

      if (title.length > 0) {
        document.title = title
      }

      url = url.replace("{{LANDBOTID}}", landbotID)
      newUrl = newUrl.replace("{{LANDBOTID}}", landbotID)

      const landbotStyle =
        ".input-buttons{direction:rtl; }.input-button-label{direction:ltr; }.MessageDate{display: none; }"

      // @ts-ignore
      let myLandbot: Landbot = new landBot.Fullpage({
        configUrl: url,
        style: landbotStyle,
      })
      myLandbot.onLoad(() => {
        try {
          if (!exception.includes(landbotID) && !myLandbot.config.hidden_fields.kamaeid) {
            myLandbot.destroy()
            // @ts-ignore
            myLandbot = new landBot.Fullpage({
              configUrl: newUrl,
              style: landbotStyle,
            })
          }
        } catch (e: any) {
          console.error("Failed to load backup bot", e)
        }
      })
    } catch {
      setLoadingError(true)
    }
  }, [landBot])

  return {
    loadingError,
  }
}
