export default function Supervision() {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 60 55"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M59 53V14H47V53H44V22H32V53H29V30H17V53H14V39H2V53H1C0.448 53 0 53.447 0 54C0 54.553 0.448 55 1 55H2H14H17H29H32H44H47H59C59.552 55 60 54.553 60 54C60 53.447 59.552 53 59 53Z" />
      <path d="M8.03249 25.3302C8.20149 25.3302 8.37449 25.2872 8.53149 25.1962L44.8005 4.25616L42.5305 9.24616C42.3015 9.74916 42.5235 10.3422 43.0265 10.5702C43.1605 10.6322 43.3015 10.6602 43.4405 10.6602C43.8205 10.6602 44.1835 10.4422 44.3515 10.0742L47.9135 2.24416C47.9245 2.21916 47.9225 2.19216 47.9315 2.16616C47.9505 2.11316 47.9655 2.06216 47.9755 2.00616C47.9805 1.97816 47.9965 1.95516 47.9985 1.92616C47.9995 1.91416 47.9945 1.90416 47.9955 1.89216C47.9975 1.85416 47.9935 1.81916 47.9915 1.78116C47.9885 1.72616 47.9795 1.67416 47.9675 1.61916C47.9595 1.58116 47.9575 1.54216 47.9445 1.50516C47.9325 1.46916 47.9115 1.43916 47.8955 1.40416C47.8825 1.38116 47.8825 1.35416 47.8685 1.33016C47.8545 1.30616 47.8305 1.29216 47.8145 1.26916C47.7935 1.23816 47.7775 1.20516 47.7525 1.17716C47.7265 1.14716 47.6935 1.12616 47.6635 1.09916C47.6225 1.06216 47.5815 1.02816 47.5355 0.998164C47.5045 0.978164 47.4765 0.956164 47.4425 0.939164C47.4315 0.934164 47.4255 0.924164 47.4145 0.919164C47.3895 0.908164 47.3625 0.910164 47.3375 0.901164C47.2825 0.881164 47.2285 0.867165 47.1715 0.857165C47.1455 0.852165 47.1245 0.837164 47.0975 0.835164L38.5355 0.00516449C37.9805 -0.0508355 37.4965 0.355165 37.4435 0.903165C37.3895 1.45316 37.7925 1.94216 38.3415 1.99516L43.7975 2.52416L7.53149 23.4642C7.05249 23.7402 6.88949 24.3522 7.16549 24.8302C7.35049 25.1512 7.68649 25.3302 8.03249 25.3302Z" />
    </svg>
  )
}
