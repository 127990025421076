import "./BotPage.css"
import MaintenancePage from "../../Error/MaintenancePage/MaintenancePage"
import { useLandbot } from "./useLandbot"

export default function BotPage() {
  const { loadingError } = useLandbot()

  if (loadingError) {
    return <MaintenancePage />
  }

  return <></>
}
