export default function DashBoard() {
  return (
    <svg
      width="29"
      height="28"
      viewBox="0 0 29 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6.68574 26.8344C6.52243 26.5293 6.41867 23.873 6.41867 19.9976V13.6598H4.20877C1.62392 13.6598 1.00082 13.4152 1.00082 12.4007C1.00082 10.7811 0.646234 10.8219 14.7193 10.8219C26.7677 10.8219 27.5184 10.8487 27.9211 11.2937C28.5066 11.9406 28.4681 13.0614 27.849 13.3927C27.5746 13.5396 26.3556 13.6598 25.1401 13.6598H22.9302V19.9976C22.9302 23.873 22.8264 26.5293 22.6631 26.8344C22.3194 27.4768 21.0901 27.4924 20.5193 26.8616C20.1462 26.4494 20.0923 25.5854 20.0923 20.0248V13.6598H14.6744H9.25657V20.0696C9.25657 25.9517 9.21774 26.5146 8.78478 26.9064C8.13782 27.4919 7.01706 27.4535 6.68574 26.8344ZM1.4523 9.25325C1.05162 9.01988 1.00082 8.51847 1.00082 4.79782C1.00082 1.881 1.09501 0.51115 1.31041 0.295761C1.80182 -0.195651 3.13922 -0.0666025 4.69763 0.622593C7.84745 2.0156 10.0793 2.41882 14.6744 2.42512C19.2791 2.43067 21.4423 2.04175 24.6312 0.631476C26.2078 -0.065764 27.5452 -0.197537 28.0385 0.295761C28.2539 0.511169 28.3481 1.88184 28.3481 4.80156C28.3481 8.68618 28.311 9.0176 27.849 9.26484C27.192 9.61648 19.1844 9.61648 18.5274 9.26484C17.885 8.92105 17.8694 7.69178 18.5002 7.12097C18.8889 6.76914 19.5702 6.69398 22.3701 6.69398H25.7682V4.98943C25.7682 3.64464 25.6929 3.31376 25.4115 3.42174C20.8964 5.15436 15.4118 5.78844 10.6587 5.12734C8.94009 4.88832 5.45811 4.01204 4.03228 3.45975C3.63019 3.30401 3.58079 3.47146 3.58079 4.98943V6.69398H7.02371C10.0315 6.69398 10.5206 6.75353 10.8936 7.16579C11.4791 7.81274 11.4406 8.93352 10.8215 9.26484C10.171 9.61303 2.05275 9.60298 1.4523 9.25325ZM13.0159 9.12649C11.9426 8.05321 12.8944 6.69398 14.7193 6.69398C16.4707 6.69398 17.3859 8.07357 16.333 9.12649C15.7866 9.67289 13.5623 9.67289 13.0159 9.12649Z" />
    </svg>
  )
}
