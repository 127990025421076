export default function EN(props: { size?: number }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 47.5 47.5"
      id="svg2"
      width={props.size ?? 30}
      height={props.size ?? 30}
    >
      <defs id="defs6">
        <clipPath id="clipPath50">
          <path
            d="M 0,38 38,38 38,0 0,0 0,38 z"
            id="path52"
          />
        </clipPath>
      </defs>
      <g
        transform="matrix(1.25,0,0,-1.25,0,47.5)"
        id="g12"
      >
        <g
          transform="translate(1,27.9409)"
          id="g14"
        >
          <path
            d="m 0,0 0,-3.941 5.628,0 L 0,0 z"
            id="path16"
            style={{
              fill: "#226699",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
          />
        </g>
        <g
          transform="translate(5.6641,6)"
          id="g18"
        >
          <path
            d="m 0,0 8.336,0 0,5.837 L 0,0 z"
            id="path20"
            style={{
              fill: "#226699",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
          />
        </g>
        <g
          transform="translate(24,11.8359)"
          id="g22"
        >
          <path
            d="m 0,0 0,-5.836 8.335,0 L 0,0 z"
            id="path24"
            style={{
              fill: "#226699",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
          />
        </g>
        <g
          transform="translate(1,14)"
          id="g26"
        >
          <path
            d="M 0,0 0,-3.941 5.63,0 0,0 z"
            id="path28"
            style={{
              fill: "#226699",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
          />
        </g>
        <g
          transform="translate(32.3369,32)"
          id="g30"
        >
          <path
            d="m 0,0 -8.337,0 0,-5.837 L 0,0 z"
            id="path32"
            style={{
              fill: "#226699",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
          />
        </g>
        <g
          transform="translate(37,10.0576)"
          id="g34"
        >
          <path
            d="m 0,0 0,3.942 -5.631,0 L 0,0 z"
            id="path36"
            style={{
              fill: "#226699",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
          />
        </g>
        <g
          transform="translate(37,24)"
          id="g38"
        >
          <path
            d="M 0,0 0,3.941 -5.629,0 0,0 z"
            id="path40"
            style={{
              fill: "#226699",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
          />
        </g>
        <g
          transform="translate(14,32)"
          id="g42"
        >
          <path
            d="M 0,0 -8.336,0 0,-5.837 0,0 z"
            id="path44"
            style={{
              fill: "#226699",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
            }}
          />
        </g>
        <g id="g46">
          <g
            clipPath="url(#clipPath50)"
            id="g48"
          >
            <g
              transform="translate(26.1396,14)"
              id="g54"
            >
              <path
                d="m 0,0 9.712,-6.801 c 0.471,0.48 0.808,1.082 0.99,1.749 L 3.487,0 0,0 z"
                id="path56"
                style={{
                  fill: "#dd2e44",
                  fillOpacity: 1,
                  fillRule: "nonzero",
                  stroke: "none",
                }}
              />
            </g>
            <g
              transform="translate(14,14)"
              id="g58"
            >
              <path
                d="m 0,0 -2.141,0 -9.711,-6.8 c 0.521,-0.53 1.189,-0.909 1.938,-1.085 L 0,-0.943 0,0 z"
                id="path60"
                style={{
                  fill: "#dd2e44",
                  fillOpacity: 1,
                  fillRule: "nonzero",
                  stroke: "none",
                }}
              />
            </g>
            <g
              transform="translate(24,24)"
              id="g62"
            >
              <path
                d="m 0,0 2.141,0 9.711,6.8 C 11.331,7.33 10.663,7.709 9.915,7.885 L 0,0.943 0,0 z"
                id="path64"
                style={{
                  fill: "#dd2e44",
                  fillOpacity: 1,
                  fillRule: "nonzero",
                  stroke: "none",
                }}
              />
            </g>
            <g
              transform="translate(11.8589,24)"
              id="g66"
            >
              <path
                d="M 0,0 -9.711,6.8 C -10.182,6.32 -10.519,5.718 -10.702,5.051 L -3.487,0 0,0 z"
                id="path68"
                style={{
                  fill: "#dd2e44",
                  fillOpacity: 1,
                  fillRule: "nonzero",
                  stroke: "none",
                }}
              />
            </g>
            <g
              transform="translate(37,16)"
              id="g70"
            >
              <path
                d="m 0,0 -15,0 0,-10 2,0 0,5.836 L -4.665,-10 -4,-10 c 1.117,0 2.126,0.461 2.852,1.199 L -10.86,-2 l 3.487,0 7.215,-5.052 C -0.065,-6.715 0,-6.366 0,-6 L 0,-5.942 -5.631,-2 0,-2 0,0 z"
                id="path72"
                style={{
                  fill: "#eeeeee",
                  fillOpacity: 1,
                  fillRule: "nonzero",
                  stroke: "none",
                }}
              />
            </g>
            <g
              transform="translate(1,16)"
              id="g74"
            >
              <path
                d="M 0,0 0,-2 5.63,-2 0,-5.941 0,-6 C 0,-7.091 0.439,-8.078 1.148,-8.8 L 10.859,-2 13,-2 13,-2.943 3.086,-9.885 C 3.38,-9.954 3.684,-10 4,-10 L 4.664,-10 13,-4.163 13,-10 15,-10 15,0 0,0 z"
                id="path76"
                style={{
                  fill: "#eeeeee",
                  fillOpacity: 1,
                  fillRule: "nonzero",
                  stroke: "none",
                }}
              />
            </g>
            <g
              transform="translate(37,28)"
              id="g78"
            >
              <path
                d="M 0,0 C 0,1.091 -0.439,2.078 -1.148,2.8 L -10.859,-4 -13,-4 l 0,0.943 9.915,6.942 C -3.38,3.954 -3.684,4 -4,4 L -4.663,4 -13,-1.837 -13,4 -15,4 -15,-6 0,-6 0,-4 -5.629,-4 0,-0.059 0,0 z"
                id="path80"
                style={{
                  fill: "#eeeeee",
                  fillOpacity: 1,
                  fillRule: "nonzero",
                  stroke: "none",
                }}
              />
            </g>
            <g
              transform="translate(14,32)"
              id="g82"
            >
              <path
                d="M 0,0 0,-5.837 -8.336,0 -9,0 c -1.118,0 -2.126,-0.461 -2.852,-1.2 l 9.711,-6.8 -3.487,0 -7.215,5.051 C -12.935,-3.286 -13,-3.634 -13,-4 L -13,-4.059 -7.372,-8 -13,-8 l 0,-2 15,0 0,10 -2,0 z"
                id="path84"
                style={{
                  fill: "#eeeeee",
                  fillOpacity: 1,
                  fillRule: "nonzero",
                  stroke: "none",
                }}
              />
            </g>
            <g
              transform="translate(22,22)"
              id="g86"
            >
              <path
                d="m 0,0 0,10 -6,0 0,-10 -15,0 0,-3 0,-3 15,0 0,-10 6,0 0,10 15,0 0,3 0,3 -15,0 z"
                id="path88"
                style={{
                  fill: "#dd2e44",
                  fillOpacity: 1,
                  fillRule: "nonzero",
                  stroke: "none",
                }}
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
