import { useAuth0 } from "@auth0/auth0-react"
import { useCryptr } from "@cryptr/cryptr-react"
import { useWindowSize } from "@kamae-apps/shared/Hooks/useWindowSize"
import { getBeltColor } from "@kamae-apps/shared/Types/Belt/Belt"
import { CompanyStatus } from "@kamae-apps/shared/Types/Company/CompanyStatus"
import { Scope, scopeHas } from "@kamae-apps/shared/Types/Scope"
import { ConditionalRender } from "@kamae-apps/shared/old/Component/ConditionalRender/ConditionalRender"
import { DropDown, DropDownSeparator } from "@kamae-apps/shared/old/Component/DropDown/DropDown"
import { isMobileScreen } from "@kamae-apps/shared/utils"
import type { TFunction } from "i18next"
import posthog from "posthog-js"
import { FC, useState } from "react"
import { LogOut } from "react-feather"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { actualDate } from "../../../utils"
import config from "../../../variable"
import useUser from "../../hooks/useUser"
import { TutorialModal } from "../App/TutorialModal"
import { DiscoveryPill } from "../DiscoveryLicence/DiscoveryPill"
import Flag from "./Flag"

type UserProps = {
  t: TFunction
  loaded: boolean
}

export const User: FC<UserProps> = ({ loaded }) => {
  const { t } = useTranslation()
  const { user } = useUser()
  const [display, setDisplay] = useState(false)
  const screenSize = useWindowSize()
  return (
    <div className={"ml-4 flex grow items-center"}>
      <img
        className={"w-32 md:w-20"}
        src={loaded ? config.api + "/logo/" + user.company.logo : ""}
        alt={user.company.name}
      />
      <div className={"w-4 grow"} />
      <ConditionalRender
        condition={
          user.company.status === CompanyStatus.Discovery &&
          scopeHas(user.scope, Scope.COMPANY_ADMIN) &&
          !isMobileScreen(screenSize)
        }
      >
        <DiscoveryPill />
      </ConditionalRender>
      <Flag />
      <p className={"hidden pr-4 text-gray-500 md:block"}>{actualDate()}</p>
      <DropDown
        title={
          <div className={"inline-flex items-center"}>
            <div className={"mr-2 flex h-10 w-10 items-center justify-center rounded-full bg-[#FFEACA]"}>
              <img
                className={"max-h-full max-w-full"}
                src={config.api + "/avatar/" + user?.avatar + "/" + getBeltColor(user.level)}
                alt={"Avatar"}
              />
            </div>
            <p className={"mr-2 hidden lg:block"}>
              {user.first_name} {user.last_name}
            </p>
          </div>
        }
      >
        <Link
          className={"w-full p-2 text-right"}
          to={"/profile"}
        >
          {t("app:header.menu.avatar")}
        </Link>
        <DropDownSeparator />
        <ConditionalRender condition={loaded && user.company.status !== CompanyStatus.Discovery}>
          <>
            <button
              className={"w-full px-2 py-1 text-right"}
              onClick={() => setDisplay(true)}
            >
              {t("app:header.menu.tutorial.menu")}
            </button>
            <DropDownSeparator />
          </>
        </ConditionalRender>
        <SignoutButton t={t} />
      </DropDown>
      <ConditionalRender condition={loaded && user.company.status !== CompanyStatus.Discovery}>
        <TutorialModal
          display={display}
          setDisplay={setDisplay}
        />
      </ConditionalRender>
    </div>
  )
}

type SignoutButtonProps = {
  t: TFunction
}

const SignoutButton: FC<SignoutButtonProps> = ({ t }) => {
  const cryptr = useCryptr()
  const auth0 = useAuth0()
  return (
    <button
      className={"w-full p-1 text-right"}
      onClick={() => {
        posthog.reset()
        if (cryptr.isAuthenticated()) {
          cryptr.logOut(undefined, config.app + "/login", false)
        } else if (auth0.isAuthenticated) {
          auth0.logout({ returnTo: window.location.origin + "/login" })
        }
        localStorage.clear()
      }}
    >
      {t("app:header.menu.logout")}
      <LogOut className={"no-fill inline"} />
    </button>
  )
}
