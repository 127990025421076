import type { FC, ReactNode } from "react"

type CrossSiteLinkProps = {
  to: string
  children: ReactNode
  className?: string
}

export const CrossSiteLink: FC<CrossSiteLinkProps> = ({ to, children, className }) => {
  return (
    <a
      href={to}
      target="_blank"
      className={className}
    >
      {children}
    </a>
  )
}
